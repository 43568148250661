import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <header className="header">
        <h1>Fractional CTO Services</h1>
        <p>Expert Guidance for Complex Software Challenges</p>
      </header>

      <section className="about">
        <h2>About Me</h2>
        <p>
          With over two decades of experience in software development, technical leadership, and strategic planning, I specialize in empowering startups and businesses to achieve their technology goals. My expertise lies in designing scalable software architectures, optimizing project workflows, and delivering innovative solutions under tight deadlines.
        </p>
      </section>

      <section className="services">
        <h2>Services</h2>
        <ul>
          <li>Technical leadership and fractional CTO services</li>
          <li>Software architecture design and optimization</li>
          <li>Cloud scalability and security solutions</li>
          <li>Mobile and web application development</li>
          <li>Startup incubator and project acceleration</li>
          <li>Compliance and regulatory consulting</li>
        </ul>
      </section>

      <section className="experience">
        <h2>Experience Highlights</h2>
        <ul>
          <li>
            Led development of real-time, high-speed backend systems and scalable cloud solutions.
          </li>
          <li>
            Designed and implemented end-to-end software infrastructures for mobile and web platforms.
          </li>
          <li>
            Provided strategic technology roadmaps for startups, improving project efficiency and reducing costs.
          </li>
          <li>
            Delivered robust solutions for security in storage and communication.
          </li>
          <li>
            Conducted technical coaching and guided long-term technology partnerships.
          </li>
        </ul>
      </section>

      <section className="testimonials">
        <h2>What Clients Say</h2>
        <blockquote>
          "An exceptional leader with a rare blend of technical expertise and business acumen. Their strategic guidance transformed our project." – Client Feedback
        </blockquote>
      </section>

      <footer className="footer">
        <p>Contact: andrei@fortify.pro | LinkedIn: <a href="https://www.linkedin.com/in/andrei-odeski">Andrei Odeski</a></p>
      </footer>
    </div>
  );
};

export default App;
